define('m13/controllers/contact', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    debug: true,
    message: "",
    sname: "",
    email: "",
    testemail: "",

    sendingMail: false,

    nachrichtVerfassen: true,

    actions: {

      sendMessage: function sendMessage() {

        var self = this;

        if (self.get('testemail') === "") {

          console.log('sendMessage()');

          self.set('sendingMail', true);

          var messageToSend = self.get('message');
          var nameOfSender = self.get('sname');
          var email = self.get('email');

          var application = self.controllerFor('application');

          var applicationdata = application.get('model').application.objectAt(0);
          var applicationdataJSON = JSON.stringify(applicationdata);
          //
          // var projektdatendata = application.get('model').projektdaten.objectAt(0);
          // var projektdatendataJSON = JSON.stringify(projektdatendata);
          //
          // var geometriedata = application.get('model').geometrie.objectAt(0);
          // var geometriedataJSON = JSON.stringify(geometriedata);
          //
          // var systemdata = application.get('model').system.objectAt(0);
          // var systemdataJSON = JSON.stringify(systemdata);
          //
          // var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
          // var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);
          //
          // var uebergabedaten = $.extend(true, JSON.parse(geometriedataJSON), JSON.parse(systemdataJSON), JSON.parse(lasteinwirkungdataJSON));
          //
          // var bestandstraeger = {
          //   "Querschnittswerte": {
          //     "b": geometriedata.get('TimberElement1_b'),
          //     "h": geometriedata.get('TimberElement1_h'),
          //     "t_Fastener": geometriedata.get('TimberElement1_h'),
          //     "QuerschnittsID": "1"
          //   },
          //   "vorgebohrt": false
          // };
          //
          // var verstaerkungstraeger = {
          //   "Querschnittswerte": {
          //     "b": geometriedata.get('TimberElement2_b'),
          //     "h": geometriedata.get('TimberElement2_h'),
          //     "t_Fastener": geometriedata.get('TimberElement2_h'),
          //     "QuerschnittsID": "1"
          //   },
          //   "vorgebohrt": false
          // };
          //
          // var schalung = {
          //   "Querschnittswerte": {
          //     "b": "0.0",
          //     "h": geometriedata.get('schalungsdicke'),
          //     "t_Fastener": geometriedata.get('schalungsdicke'),
          //     "QuerschnittsID": "1"
          //   },
          //   "vorgebohrt": false
          // };
          //
          // var externeLasten = [
          //   lasteinwirkungdata.get('staendigeEinwirkungen'), // ständige einwirkungen
          //   lasteinwirkungdata.get('verEinw1'), // ver. einw'). 1
          //   lasteinwirkungdata.get('verEinw2')  // ver. einw. 2
          // ];
          //
          // var standardloads = [
          //   lasteinwirkungdata.get('eigengewicht'), // vermutlich auswahl "einfeldträger"
          //   lasteinwirkungdata.get('kled1'), // kled menu 1
          //   lasteinwirkungdata.get('kled2')  // kled menu 2
          // ];
          //
          // uebergabedaten.bestandstraeger = bestandstraeger;
          // uebergabedaten.verstaerkungstraeger = verstaerkungstraeger;
          // uebergabedaten.schalung = schalung;
          // uebergabedaten.externeLasten = externeLasten;
          // uebergabedaten.lastIDs = standardloads;
          //
          // var JSONdata = JSON.stringify(uebergabedaten);
          var JSONdata = JSON.stringify(self.controllerFor('supercontroller').uebergabedatenErzeugen());

          var server = application.get('server');
          var pfad = application.get('pfad');

          var cid = applicationdata.get('Calculation_ID');

          if (self.debug) {
            console.log('cid: ' + cid);
            console.log('server: ' + server);
            console.log('pfad: ' + pfad);
            console.log('nameOfSender: ' + nameOfSender);
            console.log('messageToSend: ');
            console.log(messageToSend);
            console.log('kennung: ');
            console.log(applicationdataJSON);
            console.log('paras:');
            console.log(JSONdata);
          }

          $.ajax({
            type: "POST",
            url: server + "sendeEmail/",
            data: {
              kennung: applicationdataJSON,
              paras: JSONdata,
              cid: cid,
              body: messageToSend,
              name: nameOfSender,
              subj: 'M13 HTML CLIENT - Kontaktformular / Anfrage',
              sender: email,
              receiver: "support@swg-engineering.de",
              suffix: "hi13"
            }
          }).done(function (data) {
            self.set('sendingMail', false);
            self.set('nachrichtVerfassen', false);
            console.log(data);
            // datei öffnen, bzw. link anzeigen
          });
        }
      },

      neueNachrichtVerfassen: function neueNachrichtVerfassen() {
        var self = this;
        self.set('message', "");
        this.transitionToRoute('projektdaten');
        self.set('nachrichtVerfassen', true);
      }

    }
  });

});